<template>
  <v-container>
    <v-row v-if="loaded===true">
      <v-col>
        <h1>{{$store.getters.getSelectedFormTitle}}</h1>
        <FormViewer
            :username="name"
            :supervisor="supervisor"
            :titleRule="helper.titleRule"
            :model="localModel"
            :schema="$store.getters.getSelectedForm.schema"
            :minimumTimeSecond="$store.getters.getSelectedForm.minimumTimeSecond"
            :render="renderKey"
        ></FormViewer>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
            type="table-heading, list-item-two-line,table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormViewer from "@/components/FormViewer.vue";
import helper from "@/helper/Helper";
import tr from "vue2-datepicker/locale/es/tr";


export default {
  name: "FormTake",
  computed: {
    tr() {
      return tr
    },
    helper() {
      return helper
    },
    name() {
      if (this.$route.params.uuid !==undefined) {
          return this.$store.getters.getEmployee.name
      } else {
        if (this.$route.params.name!==undefined) {
          return this.$route.params.name
        }
      }
      return this.$store.getters.getSelectedForm.username
    },
    supervisor() {
      if (this.$route.params.uuid !==undefined) {
        return this.$store.getters.getSupervisor.name
      } else {
        if (this.$route.params.supervisor!==undefined) {
          return this.$route.params.supervisor
        }
      }

      return ""
    }
  },
  components : {FormViewer},
  data:() => ({
    renderKey:0,
    loaded: false,
    localModel:{}
  }),
  methods:{
    loadForm() {
      if ((this.$route.params.ShareId ==undefined && this.$route.params.Version ==undefined) && this.$route.params.uuid ===undefined) {
        this.router.push("/")
      }

      if (this.$route.params.uuid !==undefined) {
        this.$store.dispatch("fetchPrivateForm", {uuid:this.$route.params.uuid} ).then(()=>{
          this.localModel = {...this.$store.getters.getSelectedForm.model};
          this.loaded=true;
        })
      } else {
        this.$store.dispatch("fetchPublicForm", {ShareId:this.$route.params.ShareId, Version: this.$route.params.Version} ).then(()=>{
          this.localModel = {...this.$store.getters.getSelectedForm.model};
          this.loaded=true;
        })
      }

    }
  },
  mounted() {
    this.loadForm()
  }
}
</script>

<style scoped>

</style>