<template>
  <v-app>
    <v-app-bar
      app
      dense
      color="primary"
      dark
      class="d-print-none"
    >
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title>
          <router-link to="/">
          <v-img
            alt="rf Logo"
            class="shrink mr-2"
            contain
            src="https://momenteo.s3.amazonaws.com/uploads/image/file/6973/Logo.png"
            transition="scale-transition"
            width="250"
          />
          </router-link>
        </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
          plain
          v-if="$store.getters.isAuth===false"
          @click="$router.push('/login')">
        <v-icon> mdi-login </v-icon>
        Connexion
      </v-btn>
      <v-btn
          plain
          v-if="$store.getters.isAuth===true"
          @click="$router.push('/admin/employee')">
        <v-icon> mdi-account-group </v-icon>
        Employés
      </v-btn>
      <v-btn
          plain
          v-if="$store.getters.isAuth===true"
          @click="$store.dispatch('logout')">
        <v-icon> mdi-logout </v-icon>
        Déconnexion
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer padless>
      <v-col
          class="text-center"
          cols="12"
      >
      Par Nicolas R. Fournier© -  {{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
    <message-show ref="confirm"></message-show>
    <v-overlay  :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

import MessageShow from "@/components/MessageShow.vue";

export default {
  name: 'App',
  components: {MessageShow},
  data: () => ({
    //
    overlay:false
  }),
  mounted() {
    this.$store.dispatch("auth")
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$overlay = this.overlay
  }
};
</script>
