<template>
  <v-container>
    <v-row v-if="$store.getters.getSelectedForm.answers.length!==0 && $store.getters.getSelectedForm.answers[$route.params.index].schema">
      <v-col>
        <v-row>
        <h1>{{$store.getters.getSelectedFormTitle}}</h1>
        </v-row>
        <v-row>
          <v-list three-line>
            <template v-for="(section, sectionKey) in $store.getters.getSelectedForm.answers[$route.params.index].schema.properties">
              <v-list-item
                  v-if="!isNaN(helper.sectionGetResult(section,sectionKey,$store.getters.getSelectedForm.answers[$route.params.index].model))"
                  :key="sectionKey"
              >
                <v-list-item-content>
                  <v-list-item-title  v-text="section.title"></v-list-item-title>
                  <v-rating
                      length="5"
                      :value="helper.sectionGetResult(section,sectionKey,$store.getters.getSelectedForm.answers[$route.params.index].model)*5.0/100"
                      :color="helper.sectionGetResultColor(section,sectionKey,$store.getters.getSelectedForm.answers[$route.params.index].model)"
                      :background-color="helper.sectionGetResultColor(section,sectionKey,$store.getters.getSelectedForm.answers[$route.params.index].model)"
                      large
                      readonly
                      half-increments
                  >
                  </v-rating>
                  <v-list-item-subtitle v-html="helper.sectionGetResult(section,sectionKey,$store.getters.getSelectedForm.answers[$route.params.index].model)+'%'"></v-list-item-subtitle>
                  <v-divider></v-divider>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-list>
        </v-row>

        <v-row>
          <h2>Résultat de {{$store.getters.getSelectedForm.answers[$route.params.index].name}}</h2>
        </v-row>
        <v-row>
          <h2>Détail</h2>
        </v-row>
        <v-row>
          <FormViewer
              :username="$store.getters.getSelectedForm.answers[$route.params.index].name"
              :titleRule="helper.titleRule"
              :model="$store.getters.getSelectedForm.answers[$route.params.index].model"
              :schema="$store.getters.getSelectedForm.answers[$route.params.index].schema"
              :render="renderKey"
              :viewMode="true"
          ></FormViewer>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <div class="text-xl-center justify-center">
        <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import FormViewer from "@/components/FormViewer.vue";
import helper from "@/helper/Helper";


export default {
  name: "AnswerView",
  computed: {
    helper() {
      return helper
    }
  },
  components : {FormViewer},
  data:() => ({
    renderKey:0
  }),
  methods:{
    loadForm() {
      if (this.$store.getters.getSelectedForm.answers.length===0) {
        this.$store.dispatch('fetchFormWithAnswer',{id:this.$route.params.id}).catch( (err)=>{
          console.log(err)
        })
      }

      if (this.$route.params.index ==undefined) {
        this.router.push("/admin/result/"+this.$route.params.id )
      }
    }
  },
  mounted() {
    this.loadForm()
  }
}
</script>

<style scoped>

</style>
