<template>
  <div v-if="schema">
    <div v-if="!submitted">
      <v-form ref="form" v-model="valid"  v-on:submit.prevent>
        <v-text-field
            v-model="localFullName"
            :rules="titleRule"
            :counter="255"
            label="Nom complet"
            :disabled="viewMode"
            required
        ></v-text-field>
        <v-text-field
            v-if="bySupervisor===true"
            v-model="localSupervisor"
            :rules="titleRule"
            :disabled="viewMode"
            :counter="255"
            label="Nom complet du superviseur"
            required
        ></v-text-field>
        <v-jsf
            :key="render"
            v-model="localModel"
            :schema="schema"
            :disableAll="viewMode"
            :options="optionsVJSF"
        />
        <p v-if="editorMode">Valide={{ valid ? "oui" : "non" }}</p>
        <pre></pre>
        <v-btn
            :disabled="editorMode || viewMode || (!valid || !minTimeDone)"
            color="success"
            @click="submit"
            type="submit"
        >
          Soumettre
        </v-btn>
        <v-btn color="primary" @click="$refs.form.validate()">Validation</v-btn>

        <pre></pre>
        <v-alert v-if="!minTimeDone" type="warning">
          Vous devez passer au moins {{ minimumTimeSecond }} secondes avant de pouvoir soumettre.
        </v-alert>
      </v-form>
    </div>
    <div v-else>
      <v-row class="text-center">
        <v-col>
          <h1>Merci {{ localFullName }}</h1>
          <p>Le formulaire a bien été sauvegardé</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import '@koumoul/vjsf/lib/VJsf.css'
import '@koumoul/vjsf/lib/deps/third-party.js'



export default {
  name: "FormViewer",
  components: {VJsf},
  computed: {
    bySupervisor() {
      return this.supervisor !== undefined && this.supervisor !== ""
    }
  },
  data: () => ({
    valid: false,
    loading: true,
    submitted: false,
    readOnly: true,
    startedAt: 0,
    minTimeDone: false,
    timerEverySecond: null,
    localModel:{},
    optionsVJSF:{
      locale:"fr",
    },
    localFullName:"",
    localSupervisor:""
  }),
  props: {
    render: Number,
    minimumTimeSecond: Number,
    username: String,
    supervisor: String,
    titleRule: Array,
    schema: Object,
    model: {
      type: Object,
    },
    editorMode: Boolean,
    viewMode: Boolean,
  },
  methods: {
    submit() {
      this.$root.overlay = true;
      let payload = {
        model: this.localModel,
        startedAt: this.startedAt.getTime(),
        name: this.localFullName,
        bySupervisor: this.bySupervisor,
        supervisorName: this.localSupervisor,
        employeeID: this.$store.getters.getEmployee.ID,
        supervisorID: this.$store.getters.getSupervisor.ID,
        shareLinkUUID: this.$store.getters.getShareLinkUUID
      };
      this.$store.dispatch('submitForm', payload).then(() => {
            this.$root.overlay = false
            this.submitted = true
          }
      ).catch(() => {
        this.$root.overlay = false
      })
    },
    loopSecond() {
      if (this.minimumTimeSecond === 0 || this.minimumTimeSecond === undefined) {
        this.minTimeDone = true
        clearInterval(this.timerEverySecond)
        return
      }

      const endTime = new Date();
      let timeDiff = endTime - this.startedAt; //in ms
      timeDiff = Math.round(timeDiff / 1000);

      this.minTimeDone = this.minimumTimeSecond <= timeDiff
    }
  },
  beforeMount() {
    if (this.editorMode===false && this.viewMode===false){
      this.$store.commit("SET_STEPPER_FORM")
      this.optionsVJSF.verticalStepperProps = true
      this.optionsVJSF.rootDisplay = ""
    } else {
      this.$store.commit("RESET_STEPPER_FORM")
      this.optionsVJSF.verticalStepperProps = false
    }
  },
  mounted() {
    this.startedAt = new Date();
    this.localModel = {...this.model}
    this.localFullName = this.username
    this.localSupervisor = this.supervisor+""
    this.timerEverySecond = setInterval(() => {
      this.loopSecond()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timerEverySecond)
  }
}
</script>

<style scoped>

</style>