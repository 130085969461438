<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-sheet width="400" class="mx-auto">
      <v-form fast-fail @submit.prevent="login">
        <h1>Connexion</h1>
        <v-text-field v-model="email" label="Email" name="email" type="email" required></v-text-field>

        <v-text-field v-model="password" label="Mot de passe" name="password" type="password" required></v-text-field>
        <!--a href="#" class="text-body-2 font-weight-regular">Mot de passe oublié?</a-->

        <v-btn type="submit" color="primary" block class="mt-2">Connexion</v-btn>

      </v-form>
      <!--div class="mt-2">
        <p class="text-body-2">Don't have an account? <a href="#">Sign Up</a></p>
      </div-->
    </v-sheet>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      // Your login logic here
      this.$store.dispatch("login", {email:this.email, password:this.password}).then(
        this.$router.push("/")
      ).catch(()=>{

      })
    },
  },
}
</script>