<template>

  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
          <h1>Liste des employées</h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="$store.getters.getEmployeeList"
            sort-by="name"
            :search="search"
            class="elevation-1"
            :key="renderKey"
        >
          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                  color="warning"
                  dark
                  class="mb-2"
                  @click="exportCSV"
              >
                Exporter
              </v-btn>
              <v-btn
                  color="warning"
                  v-if="$store.getters.getIsAdmin===true && !importShow"
                  dark
                  class="mb-2"
                  @click="importDialog"
              >
                Importer
              </v-btn>
              <v-file-input
                  v-if="$store.getters.getIsAdmin===true && importShow"
                  @change="importEmployee"
              ></v-file-input>
              <v-dialog
                  v-model="dialog"
                  max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="success"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    Ajouter
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Éditer</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.name"
                              label="Nom complet"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-text-field
                              v-model="editedItem.email"
                              label="Courriel"
                              type="email"
                          ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="6"
                            md="4"
                        >
                          <v-autocomplete
                              v-model="editedItem.supervisorID"
                              :items="$store.getters.getEmployeeList"
                              label="Superviseur"
                              item-text="name"
                              item-value="ID"
                              clearable
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                    >
                      Annuler
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                    >
                      Sauvegarder
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer cet élément? (Supprime aussi les réponses aux formulaires.)</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.supervisorID="{ item }">
              {{ $store.getters.getEmployeeByID(item.supervisorID) ? $store.getters.getEmployeeByID(item.supervisorID).name : "" }}
          </template>
          <template v-slot:item.answerCount="{ item }">
            <router-link :to="'/admin/employee/'+ item.ID + '/'">
              {{item.answerCount}}
            </router-link>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="loadBaseData"
            >
              Rafraichir
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios";

  export default {
    name: 'EmployeeList',

    data: () => ({
      renderKey:0,
      dialog: false,
      importShow: false,
      dialogDelete: false,
      search:"",
      selectedRows:[],
      headers: [
        { text: 'Nom complet', value: 'name' },
        { text: 'Courriel', value: 'email' },
        { text: 'Nombre de formulaire complété', value: 'answerCount' },
        { text: 'Superviseur', value: 'supervisorID' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        ID: 0,
        supervisorID: 0,
      },
      defaultItem: {
        name: '',
        email: '',
        ID: 0,
        supervisorID: 0,
      },
    }),
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    mounted() {
      this.loadBaseData()
    },
    methods: {
      loadBaseData() {
        this.$store.dispatch('fetchEmployeeList',{id:this.$route.params.id}).catch( (err)=>{
          console.log(err)
        })
      },
      editItem (item) {
        this.editedIndex = this.$store.getters.getEmployeeList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.$store.getters.getEmployeeList.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        //this.desserts.splice(this.editedIndex, 1)
        this.$store.dispatch("deleteEmployee",this.editedItem)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          this.$store.dispatch("updateEmployee",this.editedItem).then(()=>{this.renderKey++})
        } else {
          this.$store.dispatch("addEmployee",this.editedItem)
        }
        this.close()
      },
      importDialog(){
        this.importShow = true;
      },
      importEmployee(file){
        this.importShow = false;
        if (file !== undefined) {
          this.$root.$confirm(
              'Importation',
              'Êtes-vous sure de vouloir importer ce fichier ' + file.name,
              {color: 'red'})
              .then((confirm) => {
                if (confirm === true) {
                  let formData = new FormData();
                  formData.append('file', file);
                  axios.post('/api/v1/employee-csv', formData, {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  }).then(
                      (data) => {
                        if (data.data && data.data.Status == 200 && data.data.Data != undefined){
                          this.$toast.success(data.data.Data)
                          this.loadBaseData()
                        }
                      }
                  )
                }
              })
        }
      },
      exportCSV() {
        window.open('/api/v1/employee-csv ','_blank');
      }
    }
  }
</script>
