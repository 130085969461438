<template>
  <v-row dense>
    <v-btn
        dark
        small
        absolute
        right
        top
        color="success"
        :disabled="enable"
        @click="addQuestion">
      Ajouter une question<v-icon dark>
      mdi-plus
    </v-icon></v-btn>
    <v-col cols="12" v-for="(value, questionKey) in  questionsParsed" :key="questionKey" >
      <v-card
          elevation="2"
          outlined
          :id="questionKey"
          >
        <v-card-title>
          <v-row>
            <v-col>
              <v-btn
                  fab
                  dark
                  small
                  absolute
                  right
                  top
                  color="error"
                  :disabled="enable"
                  @click="deleteQuestion(questionKey)"
              ><v-icon dark>
                mdi-delete
              </v-icon></v-btn>
              <v-textarea
                  v-model="value.title"
                  :counter="255"
                  label="Titre"
                  :disabled="enable"
                  :rules="titleRule"
                  required
                  maxlength="255"
                  rows="2"
                  @change="$emit('force-render',true)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text :key="renderKey">
          <v-textarea
              v-model="value.description"
              :counter="1024"
              label="Description"
              :disabled="enable"
              rows="1"
              maxlength="1024"
          ></v-textarea>

          <v-row>
            <v-col>
              <v-select
                  :value="questionType(value)"
                  :disabled="enable"
                  :items="questionOptions"
                  @change="(event) => changeType(value,event)"
                  label="Type"
                  required            >

              </v-select>
            </v-col>
            <v-col>
              <v-checkbox
                  label="Réponse obligatoire?"
                  v-model="value.required"
                  :disabled="enable"
                  @change="changeRequired(questionKey)"
              />
            </v-col>
          </v-row>

          <div v-if="questionType(value)==='number'">
            <v-text-field
                v-model="value.minimum"
                :disabled="enable"
                label="Minimum"
                type="number"
                value="0"
            ></v-text-field>
            <v-text-field
                v-model="value.maximum"
                :disabled="enable"
                label="Maximum"
                type="number"
                value="100"
            ></v-text-field>
          </div>
          <div v-if="questionType(value)==='text' || questionType(value)==='textarea'">
            <v-text-field
                v-model="value.minLength"
                label="Minimum de caractère"
                :disabled="enable"
                type="number"
            ></v-text-field>
            <v-text-field
                v-model="value.maxLength"
                label="Maximum de caractère"
                :disabled="enable"
                type="number"
            ></v-text-field>
          </div>
          <div v-if="questionType(value)==='radio' || questionType(value)==='array'">

            <v-list>
              <div v-if="questionType(value)==='radio'">
                <template v-for="(choice,index) in value.oneOf">
                  <v-list-item :key="index" v-if="choice!==undefined && choice!==null">
                    <v-list-item-content>
                      <v-text-field
                          v-model="choice.title"
                          :label="'Choix #'+ (index+1)"
                          maxlength="255"
                          :disabled="enable"
                          @change="$emit('force-render',true)"
                      >
                      </v-text-field>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                          fab
                          dark
                          small
                          color="error"
                          :disabled="enable"
                          @click="deleteChoice(index,choice.title, questionKey)"
                      ><v-icon dark>
                        mdi-minus
                      </v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </div>
              <div v-else>
                <template v-for="(choice,index) in value.items.oneOf">
                  <v-list-item :key="index" v-if="choice!==undefined && choice!==null">
                    <v-list-item-content>
                      <v-text-field
                          v-model="choice.title"
                          :label="'Choix #'+ (index+1)"
                          maxlength="255"
                          :disabled="enable"
                          @change="$emit('force-render',true)"
                      >
                      </v-text-field>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn
                          fab
                          dark
                          small
                          color="error"
                          :disabled="enable"
                          @click="deleteChoice(index,choice.title, questionKey)"
                      ><v-icon dark>
                        mdi-minus
                      </v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </div>
            </v-list>
            <v-btn
                dark
                small
                left
                bottom
                color="success"
                :disabled="enable"
                @click="addChoice(value)"
            >
              Ajouter un choix <v-icon dark>
              mdi-plus
            </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-btn
        dark
        small
        right
        bottom
        color="success"
        class="d-flex justify-end"
        :disabled="enable"
        @click="addQuestion">
      Ajouter une question<v-icon dark>
      mdi-plus
    </v-icon></v-btn>
  </v-row>
</template>

<script>
import helper from "../helper/Helper";

export default {
  name: "QuestionEditor",
  props: {
    sectionKey: String,
    questions: Object,
    enable: Boolean,
    titleRule: Array
  },
  data: () => ({
    questionOptions: [
      {text:"Entrée de texte simple",value:"text"},
      {text:"Entrée de texte à plusieurs lignes",value:"textarea"},
      {text:"Choix unique",value:"radio"},
      {text:"Choix multiple",value:"array"},
      {text:"Date",value:"date"},
      {text:"Heure",value:"time"},
      {text:"Nombre",value:"number"},
      {text:"Oui/Non",value:"boolean"},
    ],
    renderKey:0,
  }),
  computed:{
    requiredQuestion: function (sectionKey,questionKey) {
      if (sectionKey && questionKey && this.$store.getters.getSelectedForm.schema.properties[sectionKey] && this.$store.getters.getSelectedForm.schema.properties[sectionKey].required && Array.isArray(this.$store.getters.getSelectedForm.schema.properties[sectionKey].required)) {
        return this.$store.getters.getSelectedForm.schema.properties[sectionKey].required.includes(questionKey) || false
      }
      return false
    },
    questionsParsed() {
      let questions = {...this.questions}
      for (const questionKey in questions) {
        if (this.$store.getters.getSelectedForm.schema.properties[this.sectionKey].required && Array.isArray(this.$store.getters.getSelectedForm.schema.properties[this.sectionKey].required) &&this.$store.getters.getSelectedForm.schema.properties[this.sectionKey].required.includes(questionKey)) {
          questions[questionKey].required=true
        } else {
          questions[questionKey].required=false
        }
      }
      return questions
    }
  },
  watch:{
    renderKey() {
      this.$emit('force-render',true)
    },

  },
  methods: {
    addQuestion(){
      this.$store.dispatch("addNewQuestionToSection",{sectionKey:this.sectionKey}).then(
          ()=>{
            this.renderKey++;
            this.$emit('force-render-all',true)
          }
      )
    },
    changeRequired(questionKey){
      this.$store.dispatch("addChangeRequired",{sectionKey:this.sectionKey, questionKey:questionKey}).then(
          ()=>{
            //this.renderKey++;
            //this.$emit('force-render-all',true)
          }
      )
    },
    addChoice(question){
      if (this.questionType(question)==="radio")
        question.oneOf.push({"title": "Choix #"+(question.oneOf.length+1), "const": (question.oneOf.length+1)})
      if (this.questionType(question)==="array")
        question.items.oneOf.push({"title": "Choix #"+(question.items.oneOf.length+1), "const": (question.items.oneOf.length+1)})
      this.renderKey++;
    },
    changeType(question, event) {
      let items = undefined;
      if (question["items"]) {
        items= { ...question["items"] }
      }
      let oneOf = [];
      if (question["oneOf"]) {
        oneOf=  [ ...question["oneOf"] ]
      }
      question["x-display"] = undefined
      question["format"] = undefined
      question["enums"] = undefined
      question["oneOf"] = undefined
      question["items"] = undefined
      switch (event) {
        case "radio":
          question.type="number"
          question["x-display"]=event
            if (items && items["oneOf"]!==undefined){
              question["oneOf"]= items["oneOf"]
            } else {
              question["oneOf"]= [
                {"title": "Choix #1", "const": 1},
                {"title": "Choix #2", "const": 2},
              ]
            }

          break
        case "array":
          question.type="array"
          question["x-display"]="checkbox"
          question["items"] = {
              "type": "number",
                  "oneOf": [
                    {"title": "Choix #1", "const": 1},
                    {"title": "Choix #2", "const": 2},
              ]
            }
            if (oneOf.length>0) {
              question["items"]["oneOf"] = oneOf
            }
          break
        case "text":
          question.type=["string",null]
              break
        case "textarea":
          question.type="string"
          question["x-display"]="textarea"
          break
        case "date":
        case "time":
          question.type="string"
          question["format"] = event
          break
        default:
          question.type=event
      }
      this.renderKey++;
    },
    questionType(question) {
      return helper.questionType(question)
    },
    deleteQuestion(name){
      console.log(name)
      this.$root.$confirm(
          'Supprimer',
          'Êtes-vous sur de supprimer cette question : '+this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[name].title+'?',
          { color: 'red' })
          .then((confirm) => {
            if (confirm===true){
              if (this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[name]!==undefined) {
                delete this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[name]
                this.renderKey++;
              }
            }
          })
    },
    deleteChoice(index, title,  questionKey){
      console.log(index)
      console.log(questionKey)
      this.$root.$confirm(
          'Supprimer',
          'Êtes-vous sur de supprimer ce choix : '+title+'?',
          { color: 'red' })
          .then((confirm) => {
            if (confirm===true){
              if (this.questionType(this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[questionKey])==="radio")
                this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[questionKey].oneOf.splice(index,1)
              if (this.questionType(this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[questionKey])==="array")
                this.$store.state.selectedForm.schema.properties[this.sectionKey].properties[questionKey].items.oneOf.splice(index,1)
              this.renderKey++;

            }
          })
    }
  }

}
</script>

<style scoped>

</style>