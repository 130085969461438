/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/

const state = {
    selectedForm: {
        title:"Questionnaire ...",
        version:0,
        minimumTimeSecond:0,
        enable: false,
        username: "Test de nom",
        answers:[],
        model: {},
        schema: {
            "type": "object",
            "properties": {
                "objectSection1": {
                    order:0,
                    "title": "I'm a section",
                    "description": "I'm a description shown as a paragraph on top of section",
                    "type": ["object", null],
                    "properties": {
                        "stringProp1": {
                            "type": ["string", null],
                            "title": "I'm a property in section 1"
                        }
                    }
                },
                "objectSection2": {
                    order:1,
                    "title": "I'm another section",
                    "type": "object",
                    "x-display": "vertical-stepper",
                    "required": [
                        "stringProp2",
                        "stringProp5"
                    ],
                    "properties": {
                        "stringProp2": {
                            "type": "boolean",
                            "title": "I'm a property in section 2"
                        },
                        "stringProp5": {
                            "type": "number",
                            "title": "I'm a property in section 52"
                        },
                        "stringProp6": {
                            "type": "string",
                            "format": "date",
                            "title": "I'm a property in section 52"
                        },
                        "stringProp7": {
                            "type": "string",
                            "format": "time",
                            "title": "I'm a property in section 52"
                        },
                        "stringProp8": {
                            "type": "number",
                            "oneOf": [
                                {"title": "value 1", "const": 0},
                                {"title": "value 2", "const": 1}
                            ],
                            "x-display": "radio",
                            "title": "Radio"
                        },
                        "stringProp9": {
                            "type": "array",
                            "items": {
                                "type": "number",
                                "oneOf": [
                                    {"title": "value 1", "const": "0"},
                                    {"title": "value 2", "const": "1"}
                                ],
                            },
                            "x-display": "checkbox",
                            "title": "Checkbox"
                        }
                    },
                }
            }
        }
    },
    forms : [
        {"ID":-1,"CreatedAt":"0001-01-01T00:00:00Z","UpdatedAt":"0001-01-01T00:00:00Z","DeletedAt":null,"MinimumTimeSecond":10000,"name":"Test..","code":"","ShareID":"123","enable":false,"approvedBy":"Nicolas Fournier","updatedBy":"","formAnswers":null,"version":0,"schema":{},"model":{}},
    ],
    user : {
        name : "",
        admin:false,
    },
    employees : [
        {
            ID: 0,
            supervisorID: 0,
            name: "",
            email: "",
        }
    ],
    shareLinkUUID : "",
    employee : {
        ID: 0,
        supervisorID: 0,
        name: "",
        email: "",
    },
    supervisor: {
        ID: 0,
        supervisorID: 0,
        name: "",
        email: "",
    }

}

export default state
