<template>

  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
          <h1>Liste des formulaires</h1>
      </v-col>
    </v-row>
    <v-row v-if="loaded===true" class="text-center">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
              <v-btn
                color="warning"
                v-if="$store.getters.getIsAdmin===true && !importShow"
                dark
                class="mb-2"
                @click="importForm()"
            >
              Importer
            </v-btn>
            <v-file-input
                v-if="$store.getters.getIsAdmin===true && importShow"
                @change="importUploadForm"
            ></v-file-input>
            <v-btn
                color="success"
                v-if="$store.getters.getIsAdmin===true"
                dark
                class="mb-2"
                @click="addForm()"
            >
              Ajouter
            </v-btn>

          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="$store.getters.getForms"
              :search="search"
              :items-per-page="25"
              class="elevation-1"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Partager le formulaire {{selectedForm.title}}?</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-autocomplete
                                v-model="selectedEmployees"
                                :items="$store.getters.getEmployeeList"
                                label="Employé(s)"
                                item-text="name"
                                item-value="ID"
                                chips
                                clearable
                                deletable-chips
                                multiple
                            >
                            </v-autocomplete>
                        </v-col>
                      </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                            v-model="supervisorEnable"
                            label="Demander au superviseur aussi?"
                            ></v-switch>
                          </v-col>
                        </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
                    <v-btn color="blue darken-1" text @click="shareOneForm(selectedForm.ID)">Oui</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.title="{ item }">
              <router-link :to="'/form/'+item.ShareID + '/' + item.version">
                {{item.title}}
              </router-link>
            </template>
            <template v-slot:item.answerCount="{ item }">
              <router-link :to="'/admin/result/'+item.ID + '/'">
                {{item.answerCount}}
              </router-link>
            </template>
            <template v-slot:item.enable="{ item }">
              <v-simple-checkbox
                  v-model="item.enable"
                  disabled
              ></v-simple-checkbox>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  v-if="$store.getters.getIsAdmin===true"
                  small
                  class="mr-2"
                  @click="resultForm(item)"
              >
                mdi-eye-refresh-outline
              </v-icon>
              <v-icon
                  small
                  class="mr-2"
                  @click="shareForm(item)"
              >
                mdi-share-all
              </v-icon><v-icon
                  small
                  class="mr-2"
                  @click="shareByEmployeeForm(item)"
              >
                mdi-account-group
              </v-icon>
              <v-icon
                  v-if="$store.getters.getIsAdmin===true"
                  small
                  class="mr-2"
                  @click="editForm(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  v-if="$store.getters.getIsAdmin===true"
                  small
                  @click="exportForm(item)"
              >
                mdi-file-export
              </v-icon>
              <v-icon
                  v-if="$store.getters.getIsAdmin===true"
                  small
                  @click="deleteForm(item)"

              >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
            type="table-heading, list-item-two-line,table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  //import da from "vue2-datepicker/locale/es/da";

  export default {
    name: 'FormList',

    data: () => ({
      supervisorEnable: false,
      importShow: false,
      dialog: false,
      loaded: false,
      search:"",
      headers: [
        { text: 'Titre', value: 'title' },
        { text: 'Nombre de réponse', value: 'answerCount' },
        { text: 'Révision', value: 'version' },
        { text: 'Actif', value: 'enable' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selectedForm: {
        ID: 0,
        title:""
      },
      defaultForm: {
        ID: 0,
        title:""
      },
      selectedEmployees:[]
    }),
    mounted() {
      this.loadBaseData()
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
    },
    methods: {
      loadBaseData() {
        this.$store.dispatch('fetchForms').then(()=>{this.loaded=true}).catch( (err)=>{
          console.log(err)
        })
        this.$store.dispatch("auth").then( ()=>{
          if (this.$store.getters.getIsAdmin===true) {
            this.$store.dispatch('fetchEmployeeList').catch( (err)=>{
              console.log(err)
            })
          }
        })
      },
      openForm(event, {item}){
        this.$router.push("/form/"+item.ShareID + "/" + item.version)
      },
      editForm(item){
        this.$router.push("/admin/editor/"+item.ID)
      },
      resultForm(item){
        this.$router.push("/admin/result/"+item.ID)
      },
      shareForm(item){
        navigator.clipboard.writeText(window.location.origin  + "/form/"+item.ShareID + "/" + item.version);
        this.$toast.info('Le lien a été copier dans votre presse papier.');
      },
      shareByEmployeeForm(item){
        this.selectedForm = Object.assign({}, item)
        this.dialog = true
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedForm = Object.assign({}, this.defaultForm)
        })
      },
      shareOneForm (ID) {
        this.$store.dispatch("shareForm", {ID : ID, employees : this.selectedEmployees, supervisorEnable:this.supervisorEnable})
        this.dialog = false
      },
      addForm(){
        let title = prompt("Quel nom désirez-vous pour le nouveau formulaire?", "");

        if (title != null) {
          const payload = {
            title: title,
          }
          this.$store.dispatch("addForm",payload)
        }
      },
      importForm(){
        this.importShow = true;
      },
      importUploadForm(file){
        this.importShow = false;
        if (file !== undefined) {
          this.$root.$confirm(
              'Importation',
              'Êtes-vous sure de vouloir importer ce fichier ' + file.name,
              {color: 'red'})
              .then((confirm) => {
                if (confirm === true) {
                  file.text().then((data)=>{
                    let payload  = JSON.parse(data)
                    payload.title = prompt("Quel nom désirez-vous pour le nouveau formulaire?", payload.title);
                    if (payload.ID !==undefined) {
                      delete  payload.ID
                    }
                    if (payload.answerCount !==undefined) {
                      delete  payload.answerCount
                    }
                    if (payload.answers !==undefined) {
                      delete  payload.answers
                    }
                    this.$store.dispatch("addForm",payload)
                  })
                }
              })
        }
      },
      exportForm(item) {
        let element = document.createElement('a');
        const now = new Date();
        let payload = {...item};
        delete payload.ID
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(payload)));
        element.setAttribute('download', item.title + " R" + item.version + " " + now.toLocaleDateString() + ".form");

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      },
      deleteForm(item) {
        this.$root.$confirm(
            'Suppression de ' + item.title,
            'Êtes-vous sur de vouloir supprimer ce formulaire ? Ceci supprime aussi les résultats.',
            {color: 'red'})
            .then((confirm) => {
              if (confirm === true) {
                this.$store.dispatch("deleteForm", {id:item.ID})
              }
            })
      }
    }
  }
</script>
