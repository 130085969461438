
export default {
  dateLocalOptions : { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
  capFirstLetter(val) {
    return val.charAt(0).toUpperCase() + val.slice(1);
  },
  titleRule: [
    v => !!v || "This field is required",
    v => ( v && v.length >= 5 ) || "This field must have atleast 5 characters",
    v => ( v && v.length <= 255 ) || "This field exceeds maximum allowed characters",
  ],
  img2xUrl(val) {
    return `${val.replace(/(\.[\w\d_-]+)$/i, '@2x$1')} 2x`;
  },
  capitalize(str) {
    return str.replace( /([A-Z])/g, " $1" ).charAt(0).toUpperCase() + str.replace( /([A-Z])/g, " $1" ).slice(1)
  },
  getDateString(date, addDay) {
    return this.capitalize(this.getDate(date,addDay).toLocaleDateString(undefined,this.dateLocalOptions));
  },
  getDateLocalString(date) {
    return this.capitalize(date.toLocaleDateString(undefined,this.dateLocalOptions));
  },
  getDateISOString(date) {
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))
    return date.toISOString().split('T')[0]
  },
  getDateSmallString(date, addDay) {
    let yourDate =this.getDate(date,addDay)
    const offset = yourDate.getTimezoneOffset()
    yourDate = new Date(yourDate.getTime() - (offset*60*1000))
    return yourDate.toISOString().split('T')[0]
  },
  getPropertyFromArrayWithKeyValue(key,value, property, array, fallbackValue){
    return (array.find(t => t[key] === value) && array.find(t => t[key] === value)[property]!==undefined) ? array.find(t => t[key] === value)[property]: fallbackValue;
  },
  questionType(question) {
    if (question.type==="string" || Array.isArray(question.type)) {
      if (Array.isArray(question.type) && question.type.filter(n => n).some(n => n!=="string"))  {
        return ""
      }

      if (question["x-display"]==="textarea" && question["format"]===undefined)
        return "textarea"

      if (question["x-display"]===undefined && question["format"]===undefined)
        return "text"

      if (question["x-display"]===undefined && question["format"]!==undefined && (question["format"]==="date" || question["format"]==="time") )
        return question["format"]

      return question["x-display"]
    }
    if (question.type==="number" && question["x-display"]!==undefined && question["x-display"]==="radio"){
      return "radio"
    }
    return question.type
  },
  sectionGetResult(section, sectionKey,model){
    let result = 0.0;
    let total = 0.0;
    Object.entries(section.properties).forEach( ([questionKey,question])=>{
      switch (this.questionType(question)) {
        case "radio":
          //result += model[sectionKey][questionKey]-1;
          result += question.oneOf.findIndex(e => e.const==model[sectionKey][questionKey]);
          total+=question.oneOf.length-1;
          break
        case "array":
          model[sectionKey][questionKey].forEach( () => {
            result ++;
          })
          question.items.oneOf.forEach( () => {
            total++;
          })
          break
        case "number":
          result += model[sectionKey][questionKey];
          if (question.maximum && !isNaN(Number(question.maximum))){
              total += Number(question.maximum)
          }
          break
        case "boolean":
          if (model[sectionKey][questionKey] === true ){
            result += 1;
          }
          total += 1;
          break
        default:
      }

    })
    return Number(result/total*100.0).toFixed(2);
  },
  sectionGetResultColor(section, sectionKey,model){
    const result = this.sectionGetResult(section, sectionKey,model)
    if (isNaN(result)){
      return "black"
    } else if (result<(100*1/3)) {
      return "red"
    } else if (result<(100*2/3)){
      return "yellow"
    } else {
      return "green"
    }
  },
  getDate(date, addDay) {
    date = new Date(date)
    if (addDay == undefined) {
      addDay = 0
    }
    const firstDayOfWeek = date.getDate() - date.getDay();
    const day = new Date(date.setDate(firstDayOfWeek + addDay));
    return day
  },
  /**
   * Return true if the user account as the permission. Check the user status as well. If user is Admin, it will return true.
   * @param {string} acl A string, it can contain multiple permissions (split by a comma). Use * for everyone connected.
   */
  protectThis(acl) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if (userInfo && userInfo.userRole && (userInfo.userRole == "admin" || userInfo.userRole == "superadmin")) {
      return true;
    }
    if (userInfo && userInfo.userRole && userInfo.userStatus != "desactivated" && userInfo.permissions && Array.isArray(userInfo.permissions)) {
      acl.split(",").forEach(element => {
        if (acl == "*") {
          return true
        }

        if (userInfo.permissions.includes(element)) {
          return true
        }
      });
      //return userInfo.permissions.includes(acl) || false;
    }
    return false
  },
};
