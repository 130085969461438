/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
//import config from '@/assets/config.json'
//eslint-disable-next-line no-undef
import axios from "@/axios.js"
//import select from "@koumoul/vjsf/lib/utils/select";

const actions = {
    addNewSection({commit,state}, { after, section}) {
        let newSection ={
                order:0,
                "title": "I'm another section",
                "type": "object",
                "properties": {
                    "newQuestion": {
                        "type": ["string", null],
                        "title": "Nouvelle question",
                        "comment": "",
                        "commentEnable": false
                    }
                },
                "required": []
        }
        const UUID = new Date().getTime();
        if (state.selectedForm.schema === undefined || state.selectedForm.schema.properties===undefined) {
            state.selectedForm.schema = {properties:{}}
        }
        newSection.title = "Section "+ (Object.keys(state.selectedForm.schema.properties).length+1)
        newSection.properties[(UUID+1)] =  newSection.properties.newQuestion;
        newSection.required.push((UUID+1)+"")
        delete newSection.properties.newQuestion;
        commit('ADD_SECTION', {key:UUID, newSection,after,section})
    },
    addNewQuestionToSection({commit,state}, {sectionKey}) {
        let newQuestion ={
                "title": "Nouvelle Question ",
                "type": "string",
        }
        const UUID = new Date().getTime();
        newQuestion.title += (Object.keys(state.selectedForm.schema.properties[sectionKey].properties).length+1)
        commit('ADD_QUESTION', {sectionKey, key:UUID, newQuestion})
    },
    addChangeRequired({commit}, {sectionKey, questionKey}) {
        commit('CHANGE_REQUIRED_QUESTION', {sectionKey, questionKey})
    },
    fetchEmployeeList({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/employee")
                .then((response) => {
                    commit('SET_EMPLOYEE_LIST', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchForms({commit}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/form")
                .then((response) => {
                    commit('SET_FORMS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchFormWithAnswer({commit}, { id}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/form-result/"+id)
                .then((response) => {
                    commit('SET_FORM', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchFormWithAnswerByEmployee({commit}, { id}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/form-result-by-employee/"+id)
                .then((response) => {
                    commit('SET_FORMS', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchForm({commit}, { id}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/form/" + id)
                .then((response) => {
                    commit('SET_FORM', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    shareForm(_, { ID, employees, supervisorEnable}) {
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/form-share/" + ID, {employees : employees, supervisorEnable : supervisorEnable} )
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    deleteForm({commit}, { id}) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/v1/form/" + id)
                .then((response) => {
                    commit('DELETE_FORM', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPublicForm({commit}, { ShareId, Version}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/form-public/" + ShareId + "/" + Version)
                .then((response) => {
                    commit('SET_FORM', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPrivateForm({commit}, { uuid}) {
        return new Promise((resolve, reject) => {
            axios.get("/api/v1/share-link/" + uuid)
                .then((response) => {
                    commit('SET_PRIVATE_FORM', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addEmployee({commit},
              payload
    ){
        return new Promise((resolve, reject) => {
            axios.post(`/api/v1/employee`, payload)
                .then((response) => {
                    commit('ADD_NEW_EMPLOYEE', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    addForm({commit},
              payload
    ){
        return new Promise((resolve, reject) => {
            payload.version= 0;
            payload.schema= {};
            payload.model= {};
            payload.ShareID= crypto.randomUUID();

            axios.post(`/api/v1/form/`, payload)
                .then((response) => {
                    commit('ADD_NEW_FORM', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    submitForm({state}, {startedAt, name, model, bySupervisor,supervisorName ,shareLinkUUID, supervisorID, employeeID}
    ){
        return new Promise((resolve, reject) => {
            let payload = Object.assign({},  state.selectedForm)
            payload.model = model
            payload.employeeID = employeeID
            payload.supervisorID = supervisorID
            payload.shareLinkUUID = shareLinkUUID
            axios.post(`/api/v1/answer/${name}/${startedAt}`, payload, {params:{bySupervisor,supervisorName}})
                .then((response) => {
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    updateForm({commit,state}){
        //row.rateType = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        //row.project = row.rateType!=null && row.rateType!=undefined ? row.rateType : ""
        return new Promise((resolve, reject) => {
            state.selectedForm.minimumTimeSecond = Number(state.selectedForm.minimumTimeSecond )
            axios.put(`/api/v1/form/`+state.selectedForm.ID, state.selectedForm)
                .then((response) => {
                    commit('UPDATE_FORM', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    updateEmployee({commit}, payload){
        return new Promise((resolve, reject) => {
            if (payload && payload.supervisorID==null) {
                payload.supervisorID = 0;
            }
            axios.put(`/api/v1/employee/`+payload.ID, payload)
                .then((response) => {
                    commit('UPDATE_EMPLOYEE', response)
                    resolve(response)
                }).catch((error) => { reject(error) })
        })
    },
    deleteEmployee({commit}, { ID }) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/v1/employee/" + ID)
                .then((response) => {
                    commit('DELETE_EMPLOYEE', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchDeleteAnswer({commit}, { id }) {
        return new Promise((resolve, reject) => {
            axios.delete("/api/v1/answer/" + id)
                .then((response) => {
                    commit('DELETE_ANSWER', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    login({commit}, { email, password }) {
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/login", {email:email,password:password})
                .then((response) => {
                    commit('SET_USER', response)
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    auth({commit}){
        return new Promise((resolve, reject) => {
            axios.post("/api/v1/auth")
                .then((response) => {
                    commit('SET_USER', response)
                    resolve(response)
                })
                .catch((error) => {
                    commit('LOGOUT')
                    reject(error)
                })
        })
    },
    logout({commit}){
        axios.post("/logout")
        commit('LOGOUT')
    },
   /* addChoiceToQuestion({commit}){
        commit('ADD_CHOISE', {title:"Choix"})
    }*/
    /*   fetchProjects({commit}) {
           return new Promise((resolve, reject) => {
               axios.get(config.baseUrl + "/v1/project")
                   .then((response) => {
                       commit('SET_PROJECT', response)
                       resolve(response)
                   })
                   .catch((error) => {
                       reject(error)
                   })
           })
       },
   */
}

export default actions
