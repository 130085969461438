import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login.vue'
import FormEditor from '@/components/FormEditor.vue'
import FormTake from '@/components/FormTake.vue'
import HelloWorld from '@/components/FormList.vue'
import AnswerList from '@/components/AnswerList.vue'
import AnswerListByEmployee from '@/components/AnswerListByEmployee.vue'
import AnswerView from "@/components/AnswerView.vue";
import EmployeeList from "@/components/EmployeeList.vue";


/*
//SSO
import Casdoor from 'casdoor-vue-sdk'
//import VueCompositionAPI from '@vue/composition-api'

//Vue.use(VueCompositionAPI)
Vue.use(Casdoor,{
    serverUrl: "https://casdoor.elexco.net/",
    clientId: "8bcb7de8a48aceda6017",
    organizationName: "built-in",
    appName: "app_rh",
    redirectPath: "/callback",
})

Vue.prototype.$casdoor =Casdoor*/
//import { useCasdoor } from 'casdoor-vue-sdk';
//const  getSigninUrl  = Vue.Casdoor.getSigninUrl();

Vue.use(Router)

// eslint-disable-next-line no-new
const router = new Router({
    mode: 'history',
    routes : [
        {
            path: '/',
            name: 'HelloWorld',
            component: HelloWorld
        },
        {
            path: '/login',
            name: 'Connexion',
            component: Login
        },
        {
            path: '/admin/editor/:id',
            name: 'Éditeur',
            component: FormEditor
        },
        {
            path: '/admin/employee',
            name: 'Liste des employés',
            component: EmployeeList
        },
        {
            path: '/admin/employee/:id',
            name: 'Formulaire complété',
            component: AnswerListByEmployee
        },
        {
            path: '/admin/result/:id',
            name: 'Résultats',
            component: AnswerList
        },
        {
            path: '/admin/result/:id/:index',
            name: 'Résultat',
            component: AnswerView
        },
        {
            path: '/form/:ShareId/:Version/:name?/:supervisor?',
            name: 'Formulaire',
            component: FormTake
        },
        {
            path: '/share-link/:uuid',
            name: 'Formulaire',
            component: FormTake
        }
    ]
})

export default router