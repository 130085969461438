<template>

  <v-container>
    <v-row v-if="loaded!==true" class="text-center">
      <v-col>
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
            type="table-heading, list-item-two-line,table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="this.loaded">
      <v-col cols="12" v-if="$store.getters.getEmployeeByID($route.params.id)">
          <h1>Liste des réponses du formulaire par {{$store.getters.getEmployeeByID($route.params.id).name}}</h1>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="this.loaded">
      <v-col cols="12">
        <v-card v-if="$store.getters.getForms ">
          <v-card-title>
            <v-card-actions>

            </v-card-actions>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="$store.getters.getForms"
              item-key="ID"
              :search="search"
              :items-per-page="25"
              class="elevation-1"
              v-model="selectedRows"

          >

            <template v-slot:item.form.title="{ item }">
              <router-link :to="'/admin/result/'+ item.form.ID + '/'">
                {{item.form.title}}
              </router-link>
            </template>

          </v-data-table>
        </v-card>
        <v-card v-else>
          <v-card-title>
            Aucune donnée
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AnswerListByEmployee',

    data: () => ({
      search:"",
      selectedRows:[],
      loaded:false,
      headers: [
        { text: 'Formulaire', value: 'form.title' },
        { text: 'Date', value: 'completeAt' },
        { text: 'Temps de completion en seconde', value: 'durationSecond' },
        { text: 'Révision', value: 'form.version' },
      ],
    }),
    mounted() {
      this.loadBaseData()
    },
    methods: {

      loadBaseData() {
        if (this.$route.params.id ==undefined) {
          this.router.push("/")
        }
        this.$store.dispatch('fetchEmployeeList').catch( (err)=>{
          console.log(err)
        })
        this.$store.dispatch('fetchFormWithAnswerByEmployee',{id:this.$route.params.id}).then(()=>{this.loaded=true}).catch( (err)=>{
          console.log(err)
        })
      },
    }
  }
</script>
