import Vue from 'vue'
import App from './App.vue'



//Router
import router from "../router";

Vue.config.productionTip = false

import axios from "./axios.js"
Vue.prototype.$http = axios
//import VueAxios from 'vue-axios'
Vue.use(axios)


import vSelect from "vue-select";
Vue.component("v-select", vSelect);

// Vuex Store
import store from './store/store'
store.state.selectedForm.schema.properties = {};
Vue.prototype.$store = store;

// Translations provided by Vuetify
import vuetify from './plugins/vuetify'

import 'vue-toast-notification/dist/theme-sugar.css'
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

new Vue({
  store,
  // added by index plugin
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
