/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
import helper from "@/helper/Helper";

const mutations = {
    ADD_SECTION(state, {key, newSection, after, section}) {
        if (newSection !== undefined && key !== undefined) {
            console.log(after)
            console.log(section)
            /*if (section!==undefined) {
                if (after) {
                    state.selectedForm.schema.properties[key] = newSection
                } else {
                    //TODO Loop over all item (collision)
                    let id = Number(section)-1
                    if (state.selectedForm.schema.properties[id]===undefined) {
                        state.selectedForm.schema.properties[id] = newSection
                    } else {

                    }
                }
            } else*/ {
                state.selectedForm.schema.properties[key] = newSection
            }
        }
    },
    ADD_QUESTION(state, {sectionKey, key, newQuestion}) {
        if (newQuestion !== undefined && key !== undefined) {
            if (state.selectedForm.schema.properties[sectionKey].required === undefined || !Array.isArray(state.selectedForm.schema.properties[sectionKey].required)){
                state.selectedForm.schema.properties[sectionKey].required=[]
            }
            state.selectedForm.schema.properties[sectionKey].required.push(key+"")
            state.selectedForm.schema.properties[sectionKey].properties[key] = newQuestion
        }
    },
    CHANGE_REQUIRED_QUESTION(state, {sectionKey, questionKey}){
        if (state.selectedForm.schema.properties[sectionKey].required.includes(questionKey)) {
            state.selectedForm.schema.properties[sectionKey].required = state.selectedForm.schema.properties[sectionKey].required.filter(x=>x!=questionKey)
        } else {
            state.selectedForm.schema.properties[sectionKey].required.push(questionKey+"")
        }
    },
    SET_EMPLOYEE_LIST(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.employees = data.data.Data;
        }
    },
    SET_FORMS(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.forms = data.data.Data;
        }
    },
    DELETE_FORM(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.forms = state.forms.filter(f => f.ID !== data.data.Data.ID);
        }
    },
    SET_FORM(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.supervisor = {
                ID: 0,
                supervisorID: 0,
                name: "",
                email: "",
            }
            state.employee = {
                ID: 0,
                supervisorID: 0,
                name: "",
                email: "",
            }
            state.selectedForm = data.data.Data;

            try {
                let result = {}
                for (const sectionKey in state.selectedForm.schema.properties) {
                    let section = state.selectedForm.schema.properties[sectionKey]
                    result[sectionKey] = section.title
                    for (let i = 0; i < state.selectedForm.answers.length; i++) {
                        if (state.selectedForm.answers[i]["result"]===undefined) {
                            state.selectedForm.answers[i]["result"]= []
                        }
                        state.selectedForm.answers[i]["result"].push(
                            {
                                title: section.title,
                                key: sectionKey,
                                value : helper.sectionGetResult(section, sectionKey, state.selectedForm.answers[i].model),
                                color : helper.sectionGetResultColor(section, sectionKey, state.selectedForm.answers[i].model),
                            })
                    }
                }
                state.selectedForm.result = result
            } catch (err) {
                console.log(err)
            }

        }
    },
    SET_STEPPER_FORM(state){
        state.selectedForm.schema["x-display"] = "vertical-stepper"
    },
    RESET_STEPPER_FORM(state){
        state.selectedForm.schema["x-display"] = ""
    },
    SET_PRIVATE_FORM(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.selectedForm = data.data.Data.form;
            state.employee = data.data.Data.employee;
            if (data.data.Data.supervisorEnable && data.data.Data.supervisorEnable ===true ) {
                state.supervisor = data.data.Data.supervisor;
            } else {
                state.supervisor = {
                    ID: 0,
                    supervisorID: 0,
                    name: "",
                    email: "",
                }
            }
        }
    },
    SET_USER(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.user = data.data.Data;
        }
    },
    LOGOUT(state) {
       state.user = null
    },
    ADD_NEW_EMPLOYEE(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
            state.employees.push(data.data.Data)
        }
    },
    UPDATE_EMPLOYEE(state, data) {
        //Force update if error
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            const updatedForm = data.data.Data
            const index = state.employees.findIndex(r => r.ID == updatedForm.ID)
            if (index>=0){
                state.employees[index] = updatedForm
            }
        }
    },
    ADD_NEW_FORM(state, data) {
        if (data.data && data.data.Status == 200 && data.data.Data!=undefined) {
            state.forms.push(data.data.Data)
        }
    },
    UPDATE_FORM(state, data) {
        //Force update if error
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            const updatedForm = data.data.Data
            const index = state.forms.findIndex(r => r.ID == updatedForm.ID)
            if (index>=0){
              state.forms[index] = updatedForm
            }
        }
    },
    DELETE_ANSWER(state, data) {
        //Force update if error
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.selectedForm.answers = state.selectedForm.answers.filter(function( obj ) {
                return obj.ID !== data.data.Data.ID;
            });
        }
    },
    DELETE_EMPLOYEE(state, data) {
        //Force update if error
        if (data.data && data.data.Status == 200 && data.data.Data != undefined) {
            state.employees = state.employees.filter(function( obj ) {
                return obj.ID !== data.data.Data.ID;
            });
        }
    }
}

export default mutations
