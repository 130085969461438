import { render, staticRenderFns } from "./QuestionEditor.vue?vue&type=template&id=45b0493f&scoped=true&"
import script from "./QuestionEditor.vue?vue&type=script&lang=js&"
export * from "./QuestionEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b0493f",
  null
  
)

export default component.exports