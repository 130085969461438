<template>
  <div>

      <v-sheet
          class="overflow-hidden"
          style="position: relative;"
      >
      <v-container>
        <v-row v-if="loaded!==true" class="text-center">
          <v-col>
          <v-skeleton-loader
              class="mx-auto"
              type="card"
          ></v-skeleton-loader>

          <v-skeleton-loader
              type="table-heading, list-item-two-line,table-tfoot"
          ></v-skeleton-loader>
          </v-col>
        </v-row>
        <div  v-else>

        <!-- Header -->
        <v-row  class="text-center">
          <v-col>
            <h1>Modification du {{$store.getters.getSelectedFormTitle}}</h1>
          </v-col>
        </v-row>

        <v-row>
          <v-divider></v-divider>
        </v-row>

        <!-- Parameter editor -->
        <v-row align-content="center">
          <v-col cols="6">
            <!--TODO unique-->
            <v-text-field
                v-model="$store.state.selectedForm.title"
                :counter="255"
                label="Titre"
                :rules="helper.titleRule"
                :disabled="enable"
                required
                maxlength="255"
            ></v-text-field>
            <v-text-field
                v-model="$store.state.selectedForm.minimumTimeSecond"
                label="Temps minimum en seconde"
                :disabled="enable"
                type="number"
                min="0">
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn
                v-if="enable"
                elevation="2"
                small
                dark
                right
                color="success"
                @click="addCreateRevision()"
            >
              Modifier
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
                v-if="!enable"
                elevation="2"
                small
                dark
                right
                color="warning"
                @click="saveForm()"
            >
              Sauvegarder
              <v-icon>
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn
                color="pink"
                small
                dark
                @click.stop="drawer = !drawer"
            >
              Navigateur
            </v-btn>
            <v-switch
                label="Activé"
                v-model="$store.state.selectedForm.enable"
                :disabled="enable"
                @change="enableIt()">
            </v-switch>

          </v-col>
          <v-col cols="2">
            <v-chip
                v-if="$store.getters.getSelectedForm.version"
                color="primary"
            >Révision : {{$store.getters.getSelectedForm.version}}</v-chip>
          </v-col>
          <v-btn
              v-if="!enable && ((this.sections && Object.keys(this.sections).length===0) || this.sections===undefined)"
              elevation="2"
              small
              dark
              right
              color="success"
              @click="addNewSection()">
            Ajouter une section
          </v-btn>

        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>

        <v-row>
          <!-- Left editor -->
          <v-col>
            <h2>Éditeur</h2>
            <v-row dense :key="renderKey">
              <v-col cols="12" v-for="(value, name) in  $store.getters.getSelectedFormSections" :key="name">
                <v-card
                    elevation="2"
                    outlined
                    v-if="value!==null"
                    :id="''+name"
                    :ref="''+name"
                >
                  <v-card-title>
                    <v-text-field
                        v-model="value.title"
                        :counter="255"
                        label="Titre"
                        :disabled="enable"
                        :rules="helper.titleRule"
                        required
                        maxlength="255"
                    ></v-text-field>
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                        v-model="value.description"
                        :counter="1024"
                        label="Description"
                        :disabled="enable"
                        rows="1"
                        required
                        maxlength="1024"
                    ></v-textarea>
                    <h4>Questions</h4>
                    <v-divider></v-divider>
                    <v-col>
                      <question-editor
                      :enable="enable"
                      :section-key="name"
                      :title-rule="helper.titleRule"
                      :questions="value.properties"
                      @force-render="renderKeyPreview++;isEditing=true"
                      @force-render-all="renderKey++;isEditing=true"
                      >
                      </question-editor>
                    </v-col>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      color="primary"
                      :disabled="enable"
                  >
                    Ajouter une section avant
                    <v-icon
                        right
                        dark
                    >
                      mdi-table-row-plus-before
                    </v-icon>
                  </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="enable"
                        @click="addNewSection(name,false)"
                    >
                      Ajouter une section après
                      <v-icon
                          right
                          dark
                      >
                        mdi-table-row-plus-after
                      </v-icon>
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        :disabled="!canDelete || enable"
                        @click="deleteThisSection(name)"
                    >
                      Supprimer
                      <v-icon
                          right
                          dark
                      >
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical></v-divider>

          <!-- Preview -->
          <v-col>
            <h2>Aperçu</h2>
            <FormViewer
                :username="$store.getters.getSelectedForm.username"
                :titleRule="helper.titleRule"
                :schema="$store.getters.getSelectedForm.schema"
                :render="renderKeyPreview"
                :editorMode="true"
              ></FormViewer>
          </v-col>
        </v-row>

        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
          <v-list dense>
            <v-list-item
                v-for="(item, key) in sections"
                :key="item.title"
                link
            >
              <v-list-item-content>
                <v-list-item-title @click="scrollTo(key)">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
              <!-- TODO Question list https://v2.vuetifyjs.com/en/components/lists/#expansion-lists -->
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        </div>

      </v-container>
      </v-sheet>

  </div>
</template>

<script>
import FormViewer from "@/components/FormViewer.vue";
import QuestionEditor from "@/components/QuestionEditor.vue";
import helper from "@/helper/Helper";

export default {
  name: "FormEditor",
  components: {QuestionEditor, FormViewer},
  computed: {
    helper() {
      return helper
    },
    enable() {
      return this.$store.getters.getSelectedForm.enable
    },
    sections() {
      //const key = "order"
      return this.$store.getters.getSelectedForm.schema.properties//.sort((a, b)=> {return this.isUndefined(a,key) - this.isUndefined(b,key) || a[key] < b[key] ? -1 : 1})
    },
   /* canDelete() {
      console.log(this.sections)
      return Object.keys(this.sections).length>0
    }//TODO .filter(x=>!x.isEmpty())*/
  },
  watch: {
    renderKey(){
      this.isEditing= true;
      this.renderKeyPreview++;
    }
  },
  data: () => ({
    renderKey: 0,
    drawer: null,
    renderKeyPreview: 0,
    canDelete: true,
    showDeleteMessage: false,
    isEditing: false,
    valid: false,
    loaded: false,
    emptySection:  {
      order:0,
      "title": "I'm another section",
      "type": "object",
      "properties": {
        "newQuestion": {
          "type": ["string", null],
          "title": "Nouvelle question",
          "commentEnable":false,
          "comment": ""
        }
      },
    },
  }),
  methods: {
    isUndefined(o,k) {return o===undefined || k===undefined || k==="" || !(k in o)},
    scrollTo(name) {
      this.drawer=false;
      window.scrollTo({
        top: document.getElementById(name+"").offsetTop,
        left: 0,
        behavior: "smooth",
      });
    },
    addNewSection(propertyName, after){
      console.log(propertyName,after) //TODO
      this.isEditing=true;
      this.$store.dispatch("addNewSection", {after, section:propertyName}).then(()=>{this.renderKey++;})

    },
    deleteThisSection(propertyName){
      /*if (this.model.properties[propertyName]!==undefined) {
        delete this.model.properties[propertyName];
       //this.model.properties[propertyName]=null;
      }*/
      //todo store
      let schema =this.$store.state.selectedForm.schema
      this.$root.$confirm(
          'Supprimer',
          'Êtes-vous sur de supprimer cette section : '+schema.properties[propertyName].title+'?',
          { color: 'red' })
          .then((confirm) => {
            if (confirm===true){
              this.isEditing=true;
              if (schema.properties[propertyName]!==undefined) {
                delete schema.properties[propertyName];
                //this.canDelete = Object.keys(this.sections).length>1;
                // this.schema.properties[propertyName]=null;
                this.renderKey++;
              }
            }
          })
    },
    enableIt(){
      if (this.enable) {
        this.$root.$confirm(
            'Activer ' + this.$store.state.selectedForm.title,
            'Êtes-vous sur de vouloir activer ce formulaire ? Une fois activé, celui-ci sera révisé et les questions ne pourront pas être modifier à moins d\'une nouvelle révision.',
            {color: 'red'})
            .then((confirm) => {
              if (confirm === true) {
                console.log(confirm) //TODO
                this.$store.dispatch("updateForm").then(()=>{this.renderKey++;})
              } else {
                this.$store.state.selectedForm.enable = false;
                this.renderKey++;
              }
            })
      }
    },
    addCreateRevision() {
      if (this.enable) {
        this.$root.$confirm(
            'Ajouter une révision de ' + this.title,
            'Êtes-vous sur ajouter une révision de ce formulaire ? Il sera possible de le modifier après ceci.',
            {color: 'green'})
            .then((confirm) => {
              if (confirm === true) {
                this.$store.state.selectedForm.version++;
                this.$store.state.selectedForm.enable = false; //TODO
              }
            })
      }
    },
    saveForm() {
      this.$store.dispatch("updateForm").then(()=>{/*this.renderKey++; */this.isEditing=false;})
    },
    loadForm() {
      if (this.$route.params.id ==undefined) {
        this.router.push("/")
      }
      this.$store.dispatch("fetchForm", {id:this.$route.params.id} ).then(()=>{this.loaded=true})
    },
    preventNav(event) {
      if (!this.isEditing) return
      event.preventDefault()
      event.returnValue = ""
    },
  },
  mounted() {
    this.loadForm()
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing) {
      next (window.confirm("Des modifications peuvent êtres perdu, voulez-vous vraiment quitter?"))
    } else {
      next();
    }
  },
}
</script>

<style scoped>

</style>