/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/

const getters = {
    getEmployeeList:  state => state.employees,
    getEmployeeByID:  state => (ID) => state.employees.find(v => v.ID == ID),
    getForms:  state => state.forms,
    getSupervisor:  state => state.supervisor,
    getShareLinkUUID:  state => state.shareLinkUUID,
    getEmployee:  state => state.employee,
    isFormNameIsUnique: state => (title) => state.forms.some(x=>x.title === title),
    getSelectedForm:  state => state.selectedForm,
    getSelectedFormTitle:  state => state.selectedForm.title,
    getSelectedFormSections:  state =>  state.selectedForm.schema.properties,
    getIsAdmin:  state => state.user!== null && state.user.admin,
    isAuth:  state => state.user!== null,
 /* isLogged:  state => state.actualUser!=="",
  loggedUser:  state => state.actualUser,
  getStateWeeks:  state => state.stateWeeks,
  getWeek:  state => state.week,
  getWeekStatus:  state => state.week.state + (state.week.approvedBy!=="" ? " par " +state.week.approvedBy : ""),
  getWeekComment:  state => state.week.comment + (state.week.approvedBy!=="" ? " par " +state.week.approvedBy : ""),
  isEditable:  state => state.week.state==="En attente" || state.week.state==="" || state.week.state==="Refusé",
  isRefused:  state => state.week.state==="Refusé",
  isCancellable:  state => state.week.state==="Soumis" || state.week.state==="",
  getWeekApprovedBy:  state => state.week.approvedBy,
  getRows:  state => state.rows,
  getRowsSum: state =>(rateTypeCode) => {
    let total = 0.0
    for (const row of state.rows) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=row.monday
        total+=row.saturday
        total+=row.sunday
        total+=row.thursday
        total+=row.tuesday
        total+=row.wednesday
        total+=row.friday
        total+=row.consume
      }
    }
    return total
  },
  getRowsSumPerDay: (state, getters) => (day,rateTypeCode) => {
    let total = 0.0
    for (const row of state.rows) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=getters.getRowConsumeSum(row,day)//row[day.toString().toLowerCase()]
      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsTotalSum: (state,getters) => () => {
    let total = 0.0
    total +=  parseFloat(getters.getRowsNormalSum())
    total +=  parseFloat(getters.getRowsOverSum())
    return parseFloat(total).toFixed(2)
  },
  getRowConsumeSum : ()=>(row,day) => {
    let total = 0.0
    switch (row.rateType) {
      case 'BANQUET1/2':
      case 'BANQUET2':
      case 'BANQUET1':
      case 'BANQUE':
      case 'BANQUE-':
      case 'BANQUERG':
      case 'RG':
      case 'MALADIE':
      case 'T1/2':
      case 'T2':
        if (day===undefined || day =="") {
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
        } else {
          total += (row[day.toString().toLowerCase()])
        }
        break
      case 'ABSENCE':
      default:
    }
    return total
  },
  getRowsNormalSum: state => () => {
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'BANQUE-':
        case 'BANQUERG':
        case 'RG':
        case 'MALADIE':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsOverSum: state =>  () =>{
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'BANQUET1/2':
        case 'BANQUET2':
        case 'T2':
        case 'T1/2':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getStockSheets:  state => state.stockSheets,
  getStockSheetsSum: state =>(rateTypeCode) => {
    let total = 0.0
    for (const row of state.stockSheets) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=row.monday
        total+=row.saturday
        total+=row.sunday
        total+=row.thursday
        total+=row.tuesday
        total+=row.wednesday
        total+=row.friday
        total+=row.consume
      }
    }
    return total
  },
  getUsers:  state => state.users,
  getUserOptions:  state => state.users.map(v => ({label:  v.Code + " " + v.Title + " (" + v.Company + ")", value: v.Code })),
  getUserByCode: state => (code) => state.users.find(v => v.Code == code),
  getUserTitleByCode: state => (code) =>{
    let u = state.users.find(v => v.Code == code)
    if (u!==undefined && u.Title!==undefined )
      return u.Title + " (" + u.Company + ")"
    return ""
  },
  getUserById: state => (id) => state.users.find(v => v.Id == id),
  getUserTypeByCode: state => (code) => {
    let u = state.users.find(v => v.Code == code)
    if (u!==undefined && u.Id>0) {
      return u.TypeTS ? u.TypeTS : ""
    }
    return ""
  },
  canApproveThisUser: state => (code) => {
    let u = state.users.find(v => v.Code === code)
    if (u!==undefined && u.Approbateur2Id>0 && state.actualUserId>=0) {
      return u.Approbateur2Id===state.actualUserId
    }
    return false
  },
  getMainWorkTypes:  state => state.mainWorkTypes,
  getMainWorkTypeOptions:  state => state.mainWorkTypes.map(v => ({label:  v.Code + " " + v.Title, value: v.Code })),
  getMainWorkTypeByCode: state => (code) => state.mainWorkTypes.find(v => v.Code == code),
  getMainWorkTypeById: state => (Id) => state.mainWorkTypes.find(v => v.Id == Id),
  getRateTypes:  state => state.rateTypes,
  getRateTypeOptions:  state => state.rateTypes.map(v => ({label:  v.Code + " " + v.Title, value: v.Code })),
  getRateTypeByCode: state => (code) => state.rateTypes.find(v => v.Code == code),
  getRateTypeById: state => (Id) => state.rateTypes.find(v => v.Id == Id),
  getProjects:  state => state.projects,
  getProjectOptions:  state => state.projects.filter(x => x.OData__x00c9_tat!=="Fermé").map(v => ({label:  v.Code + " " + v.Title + " (" + v.Company + ")", value: v.Code })),
  getProjectByCode: state => (code) => state.projects.find(v => v.Code == code),
  getProjectByCodeApprovedBy: state => (code) => {
    let p = state.projects.find(v => v.Code == code)
    if (p!==undefined && p.ApprobateurId!==undefined && p.ApprobateurId>0) {
      let u = state.users.find(v => v.Id == p.ApprobateurId)
      return u ? u.Title : ""
    }
    return ""
  },
  getProjectTitleByCode: state => (code) => {
    let p = state.projects.find(v => v.Code == code)
    if (p!==undefined && p.Title!==undefined )
        return p.Title + " (" + p.Company + ")"
    return ""
  },
  getStocks:  state => state.stocks,
  getStockOptions:  state => state.stocks.map(v => ({...v,label:  v.Description, value: v.StockID })),
  getStockByStockID: state => (stockId) => state.stocks.find(v => v.StockID == stockId),
  getStockUnitByStockID: state => (stockId) => {
    let p = state.stocks.find(v => v.StockID == stockId)
    if (p!==undefined && p.Unit!==undefined )
      return p.Unit
    return ""
  },*/
}

export default getters
