/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/
import axios from 'axios'
import router from "../router";
import Vue from 'vue';

//import store from 'store'

const domain = "http://localhost:8080/"
/*
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
   // store.dispatch('logout')
    router.push('/login')
  }
  return Promise.reject(error)
})*/
const instance = axios.create({
  domain,
  mode: "no-cors",
  headers : {
    'Access-Control-Allow-Origin': '*',
  }
})

instance.interceptors.response.use((response) => {
  console.log('the response status is:', response.status);
  return response;
}, (error) => {
  if (error.response && error.response.status && error.response.status === 401) {
    console.log('the error response status is:', error.response.status);
    router.push("/login")
    return Promise.reject(error);
  } else {
      if (error.response && error.response.data && error.response.data.Error && error.response.status !== 410){
        Vue.$toast.error(error.response.data.Error);
      }

  }
});

export default instance
