<template>

  <v-container>
    <v-row v-if="loaded!==true" class="text-center">
      <v-col>
        <v-skeleton-loader
            class="mx-auto"
            type="card"
        ></v-skeleton-loader>

        <v-skeleton-loader
            type="table-heading, list-item-two-line,table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="this.loaded">
      <v-col cols="12">
          <h1>Liste des réponses du formulaire {{$store.getters.getSelectedForm.title}} Version: {{$store.getters.getSelectedForm.version}}</h1>
      </v-col>
    </v-row>
    <v-row class="text-center" v-if="this.loaded">
      <v-col cols="12">
        <v-card v-if="$store.getters.getSelectedForm.answers && Array.isArray($store.getters.getSelectedForm.answers) && $store.getters.getSelectedForm.answers.length>0">
          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                dark
                class="mb-2"
                @click="deleteSelectedRowAction"

            >
              Supprimer les lignes sélectionnées
            </v-btn>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="$store.getters.getSelectedForm.answers"
              item-key="ID"
              :search="search"
              :items-per-page="25"
              class="elevation-1"
              show-select
              v-model="selectedRows"

          >
            <template v-slot:item.name="{ item, index }">
              <router-link :to="'/admin/result/'+ item.formID + '/' + index">
                {{item.name}}
              </router-link>
              <v-icon v-if="item.employeeID!==0">
                mdi-account-group
              </v-icon>
            </template>
            <template v-slot:item.result="{item}">
              <div>
                <div class="d-flex flex-column mb-6" v-for="(i, index) in item.result" :key="index">
                  <div
                      class="d-flex justify-space-between text-caption"
                      v-if="i.value!=='NaN'" >
                    {{ i.title }} : <div :style="'background-color:'+i.color" >{{ i.value }}%</div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:item.employeeID="{ item }">
              {{ item.employeeID!==0 ? "Oui" : "Non"}}
            </template>
            <template v-slot:item.bySupervisor="{ item }">
              <v-simple-checkbox
                  v-model="item.bySupervisor"
                  :label="item.bySupervisor===true ? item.supervisorName : ''"
                  disabled
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-card>
        <v-card v-else>
          <v-card-title>
            Aucune donnée
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AnswerList',

    data: () => ({
      search:"",
      selectedRows:[],
      loaded:false,
      headers: [
        { text: 'Par', value: 'name' },
        { text: 'Lier', value: 'employeeID' },
        { text: 'Temps en seconde', value: 'durationSecond' },
        { text: 'Résultat', value: 'result' },
        { text: 'Complété le', value: 'CreatedAt' },
        { text: 'Par un superviseur', value: 'bySupervisor' },
        { text: 'Superviseur', value: 'supervisorName' },
      ],
    }),
    mounted() {
      this.loadBaseData()
    },
    methods: {
      deleteSelectedRowAction(){
        if (confirm("Êtes-vous sûr de supprimer ces réponses?") == true) {
            this.selectedRows.forEach( row =>{
              this.$store.dispatch('fetchDeleteAnswer',{id:row.ID})
            })
        }
      },
      loadBaseData() {
        if (this.$route.params.id ==undefined) {
          this.router.push("/")
        }
        this.$store.dispatch('fetchFormWithAnswer',{id:this.$route.params.id}).then(()=>{this.loaded=true}).catch( (err)=>{
          console.log(err)
        })
      },
    },
  }
</script>
